import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

var handleReload = (context, promise, reload_url, mutation) => {
  return promise
    .then((response) => {
      return Axios.get(reload_url).then((get_response) => {
        context.commit(mutation, get_response.data);
        return response.data;
      });
    })
    .catch((error) => {
      if (
        typeof error.response.data === "object" &&
        typeof error.response.data.text === "string"
      ) {
        return Promise.reject(error.response.data.text);
      } else {
        return Promise.reject(
          error.response.status + " " + error.response.statusText
        );
      }
    });
};

export default new Vuex.Store({
  state: {
    config: {
      ssid: [],
      role_client_id: null,
    },
    users: [],
    vlans: [],
    devices: [],
    unknown_devices: [],
  },
  getters: {
    vlan_lookup: (state) => {
      if (state === null) {
        return {};
      } else {
        return state.vlans.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      }
    },
    user_lookup: (state) => {
      if (state === null) {
        return {};
      } else {
        return state.users.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      }
    },
    is_user: (state) => {
      return Vue.prototype.$keycloak.authenticated;
    },
    is_admin: (state) => {
      var resource =
        Vue.prototype.$keycloak.resourceAccess[state.config.role_client_id];
      return resource !== undefined && resource.roles.includes("admin");
    },
  },
  mutations: {
    set_config(state, config) {
      state.config = config;
    },
    set_users(state, users) {
      state.users = users;
    },
    set_vlans(state, vlans) {
      state.vlans = vlans;
    },
    set_devices(state, devices) {
      state.devices = devices;
    },
    set_unknown_devices(state, unknown_devices) {
      state.unknown_devices = unknown_devices;
    },
  },
  actions: {
    reload(context) {
      return Axios.get("/config")
        .then((response) => {
          context.commit("set_config", response.data);
          return Axios.get("/api/user");
        })
        .then((response) => {
          context.commit("set_users", response.data);
          return Axios.get("/api/vlan");
        })
        .then((response) => {
          context.commit("set_vlans", response.data);
          return Axios.get("/api/device");
        })
        .then((response) => {
          context.commit("set_devices", response.data);
          return Axios.get("/api/unknown-device");
        })
        .then((response) => {
          context.commit("set_unknown_devices", response.data);
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject(error.response);
        });
    },
    create_user(context, user) {
      return handleReload(
        context,
        Axios.put("/api/user/" + user.id, user),
        "/api/user",
        "set_users"
      );
    },
    edit_user(context, user) {
      return handleReload(
        context,
        Axios.put("/api/user/" + user.id, user),
        "/api/user",
        "set_users"
      );
    },
    delete_user(context, user) {
      return handleReload(
        context,
        Axios.delete("/api/user/" + user.id),
        "/api/user",
        "set_users"
      );
    },
    create_vlan(context, vlan) {
      return handleReload(
        context,
        Axios.put("/api/vlan/" + vlan.id, vlan),
        "/api/vlan",
        "set_vlans"
      );
    },
    edit_vlan(context, vlan) {
      return handleReload(
        context,
        Axios.put("/api/vlan/" + vlan.id, vlan),
        "/api/vlan",
        "set_vlans"
      );
    },
    delete_vlan(context, vlan) {
      return handleReload(
        context,
        Axios.delete("/api/vlan/" + vlan.id),
        "/api/vlan",
        "set_vlans"
      );
    },
    create_device(context, device) {
      return handleReload(
        context,
        Axios.post("/api/device", device),
        "/api/device",
        "set_devices"
      );
    },
    delete_device(context, device) {
      return handleReload(
        context,
        Axios.delete("/api/device/" + device.mac),
        "/api/device",
        "set_devices"
      );
    },
    delete_unknown_device(context, device) {
      return handleReload(
        context,
        Axios.delete("/api/unknown-device/" + device.mac),
        "/api/unknown-device",
        "set_unknown_devices"
      );
    },
    reload_unknown_devices(context) {
      return Axios.get("/api/unknown-device").then((response) => {
        context.commit("set_unknown_devices", response.data);
      });
    },
  },
});
