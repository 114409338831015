import Vue from "vue";
import VueI18n from "vue-i18n";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import Axios from "axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(Buefy);

const i18n = new VueI18n({
  locale: null,
  fallbackLocale: "en",
  messages: {},
});

Vue.use(VueKeyCloak, {
  config: "/config/keycloak",
  init: {
    onLoad: "login-required",
    checkLoginIframe: false,
  },
  onReady: (kc) => {
    Axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${kc.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    Axios.post("/api/auth/userinfo").catch((error) => {
      console.log("Failed to update userinfo: ", error);
      return Promise.reject(error.response);
    });

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
