import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/devices",
    },
    {
      path: "/devices",
      name: "devices",
      component: () =>
        import(/* webpackChunkName: "devices" */ "./views/Devices.vue"),
    },
    {
      path: "/unknown-devices",
      name: "unknown-devices",
      component: () =>
        import(
          /* webpackChunkName: "unknown-devices" */ "./views/UnknownDevices.vue"
        ),
    },
    {
      path: "/users",
      name: "users",
      component: () =>
        import(/* webpackChunkName: "users" */ "./views/Users.vue"),
    },
    {
      path: "/vlans",
      name: "vlans",
      component: () =>
        import(/* webpackChunkName: "vlans" */ "./views/Vlans.vue"),
    },
  ],
});
