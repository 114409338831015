<template>
  <div id="app">
    <div v-if="loaded">
      <nav>
        <b-navbar>
          <template slot="start">
            <b-navbar-item tag="router-link" to="/devices" v-if="is_user">{{
              $t("navigation.devices")
            }}</b-navbar-item>
            <b-navbar-item
              tag="router-link"
              to="/unknown-devices"
              v-if="is_user"
              >{{ $t("navigation.unknown-devices") }}</b-navbar-item
            >
            <b-navbar-item tag="router-link" to="/users" v-if="is_admin">{{
              $t("navigation.users")
            }}</b-navbar-item>
            <b-navbar-item tag="router-link" to="/vlans" v-if="is_admin">{{
              $t("navigation.vlans")
            }}</b-navbar-item>
          </template>
          <template slot="end">
            <a v-on:click="reload()" class="navbar-item">{{
              $t("navigation.reload.button")
            }}</a>
            <b-navbar-dropdown v-bind:label="$t('navigation.language.label')">
              <a
                v-for="language in languages"
                v-bind:key="language"
                v-on:click="set_language(language)"
                class="navbar-item"
                >{{ $t("navigation.language." + language) }}</a
              >
            </b-navbar-dropdown>
          </template>
        </b-navbar>
      </nav>
      <section id="#main">
        <div v-if="is_user">
          <router-view />
        </div>
        <div v-else>
          <p>Authentication failed</p>
        </div>
      </section>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      languages: ["de", "en"],
      loaded: false,
    };
  },
  computed: {
    is_user() {
      return this.$store.getters.is_user;
    },
    is_admin() {
      return this.$store.getters.is_admin;
    },
  },
  methods: {
    set_language(language) {
      if (this.$i18n.messages[language] !== undefined) {
        this.set_language_inner(language);
        return Promise.resolve(language);
      } else {
        return import(
          /* webpackChunkName: "lang-[request]" */ `@/lang/${language}.json`
        )
          .then((response) => {
            this.$i18n.setLocaleMessage(language, response.default);
            this.set_language_inner(language);
            return Promise.resolve(language);
          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              message: this.$t("navigation.language.error", {
                language: language,
                error: error.code,
              }),
              type: "is-danger",
            });
          });
      }
    },
    set_language_inner(language) {
      this.$i18n.locale = language;
    },
    set_default_language() {
      var language = "en";
      for (var candidate of navigator.languages) {
        var short = candidate.split("-")[0];
        if (this.languages.includes(candidate) === true) {
          language = candidate;
          break;
        } else if (this.languages.includes(short) === true) {
          language = short;
          break;
        }
      }

      return this.set_language(language);
    },
    reload() {
      this.$store.dispatch("reload").catch((response) => {
        this.$buefy.dialog.alert({
          message: this.$t("navigation.reload.error", response),
          type: "is-danger",
        });
      });
    },
  },
  mounted() {
    this.set_default_language().then(() => {
      this.loaded = true;
    });
    this.reload();
  },
};
</script>
